import React, { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { CountryDropdown } from "react-country-region-selector";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
import AgreementModal from "./AgreementModal";
import DocuSignModal from "./DocuSignModal";
import PowerModal from "./PowerModal";
import PowerOfAttorney from "./PowerOfAttorney";
import { RemoveCircleOutline } from "@mui/icons-material";
import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";

const ClaimForm = () => {
  const [familyMembers, setFamilyMembers] = useState([]);
  const powerData = useSelector((state) => state.claimForm);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const powerRef = useRef(null);

  const [backdropopen, setbackdropopen] = useState(false);
  const [country1, setcountry] = useState("United States");
  const [countryValid, setCountryValid] = useState(false);
  const dispatch = useDispatch();

  const [defaultOptions, setDefaultOptions] = useState([]);
  const [defaultAirlineOptions, setDefaultAirlineOptions] = useState([]);

  const [selectedOriginOption, setSelectedOriginOption] = useState(null);
  const [selectedDestOption, setSelectedDestOption] = useState(null);
  const [selectedAirlineOption, setSelectedAirlineOption] = useState(null);
  const [selectedHomeOption, setSelectedHomeOption] = useState(null);
  const [signUrl, setSignUrl] = useState(null);

  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [hourStatus, setHourStatus] = useState(false);
  const [minStatus, setMinStatus] = useState(false);

  const [optionsList, setOptionsList] = useState([]);
  const [isOpenList, setIsOpenList] = useState(false);
  const [selectedAdditionalFiles, setSelectedAdditionalFiles] = useState(null);
  const [selectedPassportSubClaim, setSelectedPassportSubClaim] = useState([]);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [isPowerOfAttorneyChecked, setIsPowerOfAttorneyChecked] =
    useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [signatureCaptured, setSignatureCaptured] = useState(false);
  const [isTermsLinkClicked, setIsTermsLinkClicked] = useState(false);
  const [isAgreementLinkClicked, setIsAgreementLinkClicked] = useState(false);
  const [isPowerLinkClicked, setIsPowerLinkClicked] = useState(false);
  const [envolpeId, setEnvelopeId] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [isDocuSignModalOpen, setIsDocusignModalOpen] = useState(false);
  const [documentFetched, setDocumentFetched] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [isDocumentFetching, setIsDocumentFetching] = useState(false);
  const [triggerIndex, setTriggerIndex] = useState(null);
  const [dataToStore, setDataToStore] = useState(null);
  const [triggerFrom, setTriggerFrom] = useState("original");
  const [email, setEmail] = useState(null);
  const handleTermsChange = (event) => {
    if (isTermsLinkClicked) {
      setIsTermsChecked(event.target.checked);
    } else {
      event.preventDefault();
      Swal.fire({
        title: "Please read the Terms and Conditions",
        text: "You must click on the Terms and Conditions link and read them before checking the checkbox.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };
  function calculateDelay(actualArrivalTime, originalArrivalTime) {
    const duration = moment.duration(
      moment(actualArrivalTime).diff(moment(originalArrivalTime))
    );

    const isNegative = duration.asMilliseconds() < 0;
    const totalMinutes = Math.abs(duration.asMinutes());
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const formatted = `${isNegative ? "-" : ""}${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

    return formatted;
  }

  const [flightDetails, setFlightDetails] = useState([
    {
      airline: "",
      flight_number: "",
      airport_of_origin: "",
      airport_of_destination: "",
      date_of_travel: "",
      original_departure_time: "",
      original_arrival_time: "",
      original_departure_date: "",
      original_arrival_date: "",
      // original_departure: "",
      // original_arrival: "",
      hub_code: "",

      delayed: false,
      cancelled: false,
      missed: false,
    },
  ]);

  const [actualFlightDetails, setActualFlightDetails] = useState([
    {
      actual_airline: "",
      actual_flight_number: "",
      actual_airport_of_origin: "",
      actual_airport_of_destination: "",
      actual_date_of_travel: "",
      actual_departure_time: "",
      actual_arrival_time: "",
      actual_departure_date: "",
      actual_arrival_date: "",

      actual_hub_code: "",
      provided_by_airline: false,
      self_booked: false,
    },
  ]);

  const [delay, setDelay] = useState("00:00");

  const tabs = [
    {
      id: "flight delay / flight cancellation",
      label: "Flight Delay / Flight Cancellation",
    },
    { id: "general claims", label: "General Claims" },
  ];

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeClaimTab") ||
      "flight delay / flight cancellation"
  );

  const handleTabClick = (tabId) => {
    console.log("Clicked");
    setbackdropopen(true);
    localStorage.setItem("activeClaimTab", tabId);
    setActiveTab(tabId);
    setTimeout(() => {
      setbackdropopen((prev) => !prev);
      window.location.reload();
    }, 200);
  };

  const [flightOptions, setFlightOptions] = useState({});

  useEffect(() => {
    console.log("trigggerindex", triggerIndex);

    if (triggerIndex !== null) {
      const flight =
        triggerFrom === "actual"
          ? actualFlightDetails[triggerIndex]
          : flightDetails[triggerIndex];

      if (
        flight?.date_of_travel &&
        flight?.hub_code &&
        flight?.flight_number &&
        triggerFrom === "original"
      ) {
        console.log("170");
        fetchFlightOptions(triggerIndex);
      } else if (
        flight?.actual_date_of_travel &&
        flight?.actual_hub_code &&
        flight?.actual_flight_number &&
        triggerFrom === "actual"
      ) {
        fetchFlightOptions(triggerIndex);
      }
    }
  }, [triggerIndex, flightDetails, actualFlightDetails]);

  const fetchFlightOptions = async (index) => {
    const flight =
      triggerFrom === "actual"
        ? actualFlightDetails[index]
        : flightDetails[index];

    if (triggerFrom === "original") {
      if (
        !flight?.date_of_travel ||
        !flight?.hub_code ||
        !flight?.flight_number
      ) {
        Swal.fire({
          icon: "warning",
          title: "Missing Information",
          text: "Please fill in the original date of travel, airline, and flight number before proceeding!",
        });
        return;
      }
    } else if (triggerFrom === "actual") {
      console.log(
        "200",
        flight.actual_date_of_travel,
        flight.actual_hub_code,
        flight.actual_airline
      );
      if (
        !flight?.actual_date_of_travel ||
        !flight?.actual_hub_code ||
        !flight?.actual_flight_number
      ) {
        Swal.fire({
          icon: "warning",
          title: "Missing Information",
          text: "Please fill in the actual date of travel, airline, and flight number before proceeding!",
        });
        return;
      }
    }

    setbackdropopen(true);
    try {
      const response = await axios.post(
        `${base_url.api1}/tourapp/flight_details`,
        {
          airline_code:
            triggerFrom === "original"
              ? flight.hub_code
              : flight.actual_hub_code,
          flight_number:
            triggerFrom === "original"
              ? flight.flight_number
              : flight.actual_flight_number,
          flight_date:
            triggerFrom === "original"
              ? flight.date_of_travel
              : flight.actual_date_of_travel,
        }
      );
      console.log("triggerFrom 204", triggerFrom);

      const flightOptionsData = response.data.Flight.map((flightData) => {
        // Format the scheduled and actual departure times and dates separately

        // const departureScheduledTime = moment(flightData.departure.scheduled)
        //   .utc()
        //   .format("HH:mm");

        // const departureActualTime = moment(flightData.departure.actual)
        //   .utc()
        //   .format("HH:mm");
        // const departureScheduledDate = moment(
        //   flightData.departure.scheduled
        // ).format("YYYY-MM-DD");
        // const departureActualDate = moment(flightData.departure.actual).format(
        //   "YYYY-MM-DD"
        // );

        // Format the scheduled and actual arrival times and dates separately
        // const arrivalScheduledTime = moment(flightData.arrival.scheduled)
        //   .utc()
        //   .format("HH:mm");
        // const arrivalActualTime = moment(flightData.arrival.actual)
        //   .utc()
        //   .format("HH:mm");
        // const arrivalScheduledDate = moment(
        //   flightData.arrival.scheduled
        // ).format("YYYY-MM-DD");
        // const arrivalActualDate = moment(flightData.arrival.actual).format(
        //   "YYYY-MM-DD"
        // );

        // delay
        const departureScheduled = flightData.departure.scheduled;
        const departureActual = flightData.departure.actual;
        const arrivalScheduled = flightData.arrival.scheduled;
        const arrivalActual = flightData.arrival.actual;
        const delayDuration = moment
          .utc(moment(departureActual).diff(moment(departureScheduled)))
          .format("HH:mm");
        // const delayDuration = "";
        return {
          value: `${flightData.departure.iata}-${flightData.arrival.iata}`,
          departure: {
            airport: flightData.departure.airport,
            iata: flightData.departure.iata,
            departureScheduled,
            departureActual,
          },
          arrival: {
            airport: flightData.arrival.airport,
            iata: flightData.arrival.iata,
            arrivalScheduled,
            arrivalActual,
          },
          label: `${flightData.arrival.airport} - ${flightData.departure.airport}`,
        };
      });

      const flightList = flightOptionsData[0]; // Get flight based on triggerIndex
      console.log("237--->", flightList);
      if (flightList) {
        const updatedFlightDetails =
          triggerFrom === "actual"
            ? [...actualFlightDetails]
            : [...flightDetails]; // Assuming flightDetails is a state variable.

        if (triggerFrom === "actual") {
          // const actualArrivalTime = flightList.arrival.arrivalActual || "";
          // const originalArrivalTime =
          //   updatedFlightDetails[triggerIndex].original_arrival_time || "";

          // let delay = "";
          // if (actualArrivalTime && originalArrivalTime) {
          //   delay = calculateDelay(actualArrivalTime, originalArrivalTime);
          //   setDelay(delay);
          // }

          updatedFlightDetails[triggerIndex] = {
            ...updatedFlightDetails[triggerIndex],
            actual_departure_time: flightList.departure.departureActual
              ? moment(flightList.departure.departureActual)
                  .utc()
                  .format("HH:mm")
              : "",
            actual_departure_date:
              moment(flightList.departure.departureActual)
                .utc()
                .format("YYYY-MM-DD") || "",
            actual_arrival_time: flightList.arrival.arrivalActual
              ? moment(flightList.arrival.arrivalActual).utc().format("HH:mm")
              : "",
            actual_arrival_date: flightList.arrival.arrivalActual
              ? moment(flightList.arrival.arrivalActual)
                  .utc()
                  .format("YYYY-MM-DD")
              : "",

            actual_airport_of_origin: flightList.departure.airport || "",
            actual_airport_of_destination: flightList.arrival.airport || "",
          };
        } else if (triggerFrom === "original") {
          // const originalArrivalTime = flightList.arrival.arrivalScheduled || "";
          // const actualArrivalTime =
          //   updatedFlightDetails[triggerIndex].actual_arrival_time || "";

          // let delay = "";
          // if (originalArrivalTime && actualArrivalTime) {
          //   delay = calculateDelay(actualArrivalTime, originalArrivalTime);
          //   setDelay(delay);
          // }

          updatedFlightDetails[triggerIndex] = {
            ...updatedFlightDetails[triggerIndex],
            airport_of_origin: flightList.departure.airport || "",
            airport_of_destination: flightList.arrival.airport || "",
            original_departure_time:
              moment(flightList.departure.departureScheduled)
                .utc()
                .format("HH:mm") || "",
            original_departure_date:
              moment(flightList.departure.departureScheduled)
                .utc()
                .format("YYYY-MM-DD") || "",
            original_arrival_time:
              moment(flightList.arrival.arrivalScheduled)
                .utc()
                .format("HH:mm") || "",
            original_arrival_date:
              moment(flightList.arrival.arrivalScheduled)
                .utc()
                .format("YYYY-MM-DD") || "",
          };
        }
        if (triggerFrom === "actual") {
          setActualFlightDetails(updatedFlightDetails);
        } else {
          setFlightDetails(updatedFlightDetails);
        }
      }

      setTriggerIndex(null);

      const updatedFlightOptions = { ...flightOptions };
      updatedFlightOptions[index] = flightOptionsData;
      setFlightOptions(updatedFlightOptions);

      setbackdropopen(false);
    } catch (error) {
      console.error("Error fetching flight options:", error);
      setFlightOptions((prevState) => {
        const updatedFlightOptions = { ...prevState };
        updatedFlightOptions[index] = [];
        return updatedFlightOptions;
      });
      const updatedFlightDetails =
        triggerFrom === "actual"
          ? [...actualFlightDetails]
          : [...flightDetails];

      if (triggerFrom === "actual") {
        updatedFlightDetails[triggerIndex] = {
          ...updatedFlightDetails[triggerIndex],
          actual_departure_time: "",
          actual_arrival_time: "",
          actual_airport_of_origin: "",
          actual_airport_of_destination: "",
          delay: "",
        };
      } else if (triggerFrom === "original") {
        updatedFlightDetails[triggerIndex] = {
          ...updatedFlightDetails[triggerIndex],
          airport_of_origin: "",
          airport_of_destination: "",
          original_departure_time: "",
          original_arrival_time: "",
        };
      }

      if (triggerFrom === "actual") {
        setActualFlightDetails(updatedFlightDetails);
      } else {
        setFlightDetails(updatedFlightDetails);
      }

      setTriggerIndex(null);

      setbackdropopen(false);
    }
  };

  const addFlightDetails = () => {
    setTriggerIndex(null);
    setFlightDetails([
      ...flightDetails,
      {
        airline: "",
        flight_number: "",
        airport_of_origin: "",
        airport_of_destination: "",
        date_of_travel: "",
        original_departure_time: "",
        original_arrival_time: "",
        original_departure_date: "",
        original_arrival_date: "",
        original_departure: "",
        original_arrival: "",
        hub_code: "",

        delayed: false,
        cancelled: false,
        missed: false,
      },
    ]);
  };

  const addActualFlightDetails = () => {
    setTriggerIndex(null);
    setActualFlightDetails([
      ...actualFlightDetails,
      {
        actual_airline: "",
        actual_flight_number: "",
        actual_airport_of_origin: "",
        actual_airport_of_destination: "",
        actual_date_of_travel: "",
        actual_departure_time: "",
        actual_arrival_time: "",
        actual_departure_date: "",
        actual_arrival_date: "",
        actual_departure: "",
        actual_arrival: "",
        actual_hub_code: "",
        provided_by_airline: false,
        self_booked: false,
      },
    ]);
  };

  const removeFlightDetails = (index) => {
    setTriggerIndex(null);
    if (flightDetails.length > 1) {
      setFlightDetails(flightDetails.filter((_, i) => i !== index));
    }
  };

  const removeActualFlightDetails = (index) => {
    setTriggerIndex(null);
    if (actualFlightDetails.length > 1) {
      setActualFlightDetails(actualFlightDetails.filter((_, i) => i !== index));
    }
  };

  // const handleDelayChange = (e, index, type) => {
  //   setTriggerIndex(null);
  //   const updatedFlightDetails = [...flightDetails];
  //   let [hours, minutes] = updatedFlightDetails[index].delay.split(":");
  //   if (type === "hours") {
  //     hours = e.target.value;
  //   } else {
  //     minutes = e.target.value;
  //   }
  //   // Update the delay as "HH:MM"
  //   updatedFlightDetails[index].delay = `${hours || "00"}:${minutes || "00"}`;
  //   setFlightDetails(updatedFlightDetails);
  // };

  const handleTermsLinkClick = () => {
    setIsTermsLinkClicked(true);
  };

  const handleSignatureAdded = (signatureData) => {
    //console.log("ok");
  };

  const handlePdfGenerated = (blob) => {
    setPdfBlob(blob);
    setSignatureCaptured(true); // Set state to show "View Agreement" button
  };

  // const handleTermsChange = () => {
  //   setIsTermsChecked(!isTermsChecked);
  // };

  const handleAgreementChange = (event) => {
    if (isAgreementLinkClicked) {
      setIsAgreementChecked(event.target.checked);
    } else {
      event.preventDefault();
      Swal.fire({
        title: "Please read the Agreement",
        text: "You must click on the Agreement link and read them before checking the checkbox.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  const handlePowerOfAttorneyChange = (event) => {
    if (isPowerLinkClicked) {
      setIsPowerOfAttorneyChecked(event.target.checked);
    } else {
      event.preventDefault();
      Swal.fire({
        title: "Please read the Power of Attorney",
        text: "You must click on the Power of Attorney link and read it before proceeding.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  // const fetchSignedDocument = () => {
  //   setbackdropopen(true);

  //   // const sign_url = `${base_url.api1}/tourapp/get_signed_doc?id=${envolpeId}`;
  //   const sign_url = `${base_url.api1}/tourapp/wesign_signed_doc?id=${envolpeId}`;

  //   axios
  //     .get(sign_url)
  //     .then((response) => {
  //       if (response.data && response.data.Document) {
  //         const document_url = response.data.Document;
  //         console.log("Document URL", document_url);
  //         let pdfBlob = null;
  //         setbackdropopen(true);
  //         fetch(document_url)
  //           .then((pdfResponse) => {
  //             if (!pdfResponse.ok) {
  //               throw new Error("Failed to fetch the document.");
  //             }
  //             console.log("Finally 1");
  //             return pdfResponse.blob();
  //           })
  //           .then((blob) => {
  //             console.log("Finally 2", blob);
  //             pdfBlob = blob;
  //             setPdfBlob(pdfBlob);
  //             setSignatureCaptured(true);
  //             setDocumentFetched(true);
  //           })
  //           .catch((error) => {
  //             console.error("Error fetching or converting PDF:", error);
  //           });
  //         // pdfBlob = base64ToBlob(document_url, "application/pdf");
  //         // setPdfBlob(pdfBlob);
  //         // setSignatureCaptured(true);
  //         // setDocumentFetched(true);
  //       } else {
  //         console.error("Signed document is not present in the response.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching signed document:", error);
  //     })
  //     .finally(() => {
  //       console.log("Finally 3");
  //       setbackdropopen(false);
  //     });
  // };

  // const startFetching = () => {
  //   if (intervalId) clearInterval(intervalId);
  //   const id = setInterval(() => {
  //     if (!isFetching) {
  //       fetchSignedDocument();
  //     }
  //   }, 10000);
  //   setIntervalId(id);
  // };

  // useEffect(() => {
  //   if (envolpeId && !documentFetched) {
  //     startFetching();
  //   }
  //   return () => {
  //     if (intervalId) clearInterval(intervalId);
  //   };
  // }, [envolpeId, isFetching, documentFetched]);

  // const handleReFetch = () => {
  //   setDocumentFetched(false);
  //   startFetching();
  // };

  const fetchSignedDocument = async () => {
    try {
      setbackdropopen(true);
      // const sign_url = `${base_url.api1}/tourapp/get_signed_doc?id=${envolpeId}`;
      const sign_url = `${base_url.api1}/tourapp/wesign_signed_doc?id=${envolpeId}`;

      const response = await axios.get(sign_url);

      if (response.data && response.data.Document) {
        const document_url = response.data.Document;

        const pdfResponse = await fetch(document_url);

        if (!pdfResponse.ok) {
          throw new Error("Failed to fetch the document.");
        }

        const pdfBlob = await pdfResponse.blob();

        setPdfBlob(pdfBlob);
        setSignatureCaptured(true);
        setDocumentFetched(true);
      } else {
        console.error("Signed document is not present in the response.");
      }
    } catch (error) {
      console.error("Error fetching signed document:", error);
    } finally {
      console.log("Completed fetching the document.");
      setbackdropopen(false);
    }
  };

  const handleIframeClose = () => {
    fetchSignedDocument();
  };

  const isFormValid =
    isTermsChecked &&
    isAgreementChecked &&
    (familyMembers.length > 0 ? isPowerOfAttorneyChecked : true);

  const handleInputChange = (e, index, fieldName) => {
    const { value } = e.target;
    setFamilyMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = {
        ...updatedMembers[index],
        [fieldName]: value,
      };
      return updatedMembers;
    });
  };

  const addFamilyMember = () => {
    setFamilyMembers([
      ...familyMembers,
      {
        first_name: null,
        last_name: null,
        date_of_birth: null,
        passport_number: null,
        ticket_number: null,
        passport_file: null, // Initialize as null or handle file object
        passport_name: null,
        additional_doc: null,
        additionalDoc_name: null,
      },
    ]);
  };
  const [ticketNumberErrors, setTicketNumberErrors] = useState(
    Array(familyMembers.length).fill("")
  );

  const handleTicketNumberChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, "");
    let errorMessage = "";
    if (value.length > 13) {
      return;
    }

    if (!value) {
      errorMessage = "Ticket Number is Required";
    } else if (value.length !== 13) {
      errorMessage = "Ticket Number must be 13 digits";
    }
    const updatedErrors = [...ticketNumberErrors];
    updatedErrors[index] = errorMessage;
    setTicketNumberErrors(updatedErrors);

    setFamilyMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = {
        ...updatedMembers[index],
        ticket_number: value,
      };
      return updatedMembers;
    });
  };

  const handlePassportUpload = (e, index) => {
    const file = e.target.files[0];
    const updatedFamilyMember = [...familyMembers];
    updatedFamilyMember[index]["passport_file"] = file;
    updatedFamilyMember[index]["passport_name"] = `sub_claim_${index + 1}`;
    setFamilyMembers(updatedFamilyMember);
  };

  const handleAdditionalUpload = (e, index) => {
    const file = e.target.files[0];
    const updatedFamilyMember = [...familyMembers];
    updatedFamilyMember[index]["additional_doc"] = file;
    updatedFamilyMember[index]["additionalDoc_name"] = `sub_doc_claim_${
      index + 1
    }`;
    setFamilyMembers(updatedFamilyMember);
  };

  const removeFamilyMember = (indexToRemove) => {
    const updatedFamilyMembers = familyMembers.filter(
      (_, index) => index !== indexToRemove
    );
    setFamilyMembers(updatedFamilyMembers);
  };

  const getCurrentDateTime = () => {
    return moment().format("h:mm A MMMM D, YYYY");
  };

  const addCustomerNote = (customerNote, notes) => {
    const trimmedNote = customerNote.trim();

    // Don't add the note if it's empty, null, or only contains spaces
    if (!trimmedNote) {
      return null;
    }

    const dateTime = getCurrentDateTime();
    const newNote = {
      user: "customer",
      note: trimmedNote,
      timestamp: dateTime,
    };

    return notes ? [newNote, ...notes] : [newNote];
  };

  const fetchSuggestions = async (inputValue) => {
    if (!inputValue) {
      setOptionsList([]);
      return;
    }

    try {
      const response = await axios.get(
        `${base_url.api1}/tourapp/address_suggestion?address=${inputValue}`
      );
      const data = response.data;
      const suggestions = data.suggestions.map((suggestion) => ({
        label: suggestion.postal_code
          ? `${suggestion.description},${suggestion.postal_code}`
          : suggestion.description,
        value: suggestion.place_id,
      }));
      setOptionsList(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setOptionsList([]);
    }
  };

  const [mobile, setMobile] = useState();
  const [ticket, setTicket] = useState();

  const handleTicketInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 13) {
      return;
    }
    setTicket(value);
    setValue("tickets", value, { shouldValidate: true });
  };

  const handleMobileInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 10) {
      return;
    }
    setMobile(value);
    setValue("contact_no", value, { shouldValidate: true });
  };

  const handleAddressInputChange = (e) => {
    fetchSuggestions(e.target.value);
    setValue("address", e.target.value);
    setIsOpenList(true);
  };

  const handleAddressChange = (option) => {
    setValue("address", option.label);
    setOptionsList([]);
    setIsOpenList(false);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      setIsOpenList(false);
    }, 100);
  };

  const handleHourChange = (event) => {
    setSelectedHour(event.target.value);
    //console.log(event.target.value);
    setHourStatus(false);
  };

  const handleMinuteChange = (event) => {
    setSelectedMinute(event.target.value);
    //console.log(event.target.value);
    setMinStatus(false);
  };

  useEffect(() => {
    const lastFlightDetail = flightDetails[flightDetails?.length - 1];
    const lastActualFlightDetail =
      actualFlightDetails[actualFlightDetails?.length - 1];

    if (
      lastFlightDetail?.original_arrival_time &&
      lastFlightDetail?.original_arrival_date &&
      lastActualFlightDetail?.actual_arrival_time &&
      lastActualFlightDetail?.actual_arrival_date
    ) {
      const originalArrivalDateTime = `${lastFlightDetail.original_arrival_date}T${lastFlightDetail.original_arrival_time}`;
      const actualArrivalDateTime = `${lastActualFlightDetail.actual_arrival_date}T${lastActualFlightDetail.actual_arrival_time}`;

      const delay = calculateDelay(
        actualArrivalDateTime,
        originalArrivalDateTime
      );
      setDelay(delay);
    } else {
      setDelay("HH:MM");
    }
  }, [flightDetails, actualFlightDetails]);

  // Fetch initial data
  useEffect(() => {
    fetchEmail();
    fetchInitialAirports();
  }, []);

  const fetchEmail = async () => {
    try {
      const response = await axios.get(
        `${base_url.api1}/tourapp/get_flyandClaim_email`
      );

      // console.log("798-->", response?.data?.Email);
      setEmail(response?.data?.Email);
    } catch (error) {
      console.log("798-->", error);
    }
  };

  const fetchAirlines = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/airline_list?search=${inputValue}`
        );
        if (response.data && response.data.airlines) {
          return response.data.airlines.map((airline) => ({
            value: airline.id,
            label: airline.airline_name,
            hub_code: airline.iata_code,
          }));
        }
      } catch (error) {
        console.error("Error fetching airlines:", error);
      }
    }
    return [];
  };

  const fetchHomeSuggestions = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/address_suggestion?address=${inputValue}`
        );
        const data = response.data;
        return data.suggestions.map((suggestion) => ({
          label: suggestion.description,
          value: suggestion.description,
        }));
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        return [];
      }
    }
  };

  const handleAdditionalFileChange = (e) => {
    setSelectedAdditionalFiles(e.target.files);
  };

  const loadOptions = (inputValue, callback) => {
    fetchAirports(inputValue).then((options) => callback(options));
  };

  const fetchAirports = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/airport_list?search=${inputValue}`
        );
        if (response.data && response.data.Airports) {
          return response.data.Airports.map((airport) => ({
            value: airport.id,
            label: airport.airport_name,
          }));
        }
      } catch (error) {
        console.error("Error fetching airports:", error);
      }
    }
    return [];
  };

  const loadAirlineOptions = (inputValue, callback) => {
    fetchAirlines(inputValue).then((options) => callback(options));
  };

  const fetchInitialAirports = async () => {
    try {
      // Fetch initial airline options
      const airlineResponse = await axios.get(
        `${base_url.api1}/tourapp/airline_list`
      );
      if (airlineResponse.data && airlineResponse.data.airlines) {
        const airlineOptions = airlineResponse.data.airlines.map((airline) => ({
          value: airline.id,
          label: airline.airline_name,
          hub_code: airline.iata_code,
        }));
        setDefaultAirlineOptions(airlineOptions);
      }

      const response = await axios.get(`${base_url.api1}/tourapp/airport_list`);
      if (response.data && response.data.Airports) {
        const options = response.data.Airports.map((airport) => ({
          value: airport.id,
          label: airport.airport_name,
        }));

        setDefaultOptions(options);
      }
    } catch (error) {
      console.error("Error fetching initial airports:", error);
    }
  };

  const [pdfData, setPdfData] = useState(null);

  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [isPowerModalOpen, setIsPowerModalOpen] = useState(false);
  const [powerPdf, setPowerPdf] = useState(null);

  const openAgreementModal = () => {
    setIsAgreementLinkClicked(true);
    setAgreementModalOpen(true);
  };

  const getPowerOfAttorney = async () => {
    const content = powerRef.current;
    let htmlString = null;
    if (content) {
      htmlString = content.innerHTML;
    }
    setbackdropopen(true);

    try {
      const response = await axios.post(
        `${base_url.api1}/tourapp/power_of_attorney`,
        {
          html_string: htmlString,
        }
      );

      if (response.data && response.data.status && response.data.document) {
        const base64PDF = response.data.document;
        handlePowerPDF(base64PDF);
        setbackdropopen(false);
      }
    } catch (error) {
      console.error("Error fetching Power of Attorney PDF:", error);
      setbackdropopen(false);
    }
  };

  const handlePowerPDF = (base64PDF) => {
    const pdfBlob = base64ToBlob(base64PDF, "application/pdf");

    setPowerPdf(pdfBlob);

    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const openPowerOfAttorneyModal = () => {
    Swal.fire({
      title: "Important: Power of Attorney",
      html: `
        Please review the Power of Attorney document carefully. 
        Ensure you obtain consent from all other travelers listed. 
        After obtaining their signatures, kindly send the completed document to 
        <a href="mailto:${email}">${email}</a> 
        for further processing of your claim.
      `,
      icon: "warning",
      confirmButtonText: "OK",
    }).then(() => {
      // Code to execute after the user clicks "OK"
      setIsPowerLinkClicked(true);
      // setIsPowerModalOpen(true);
      getPowerOfAttorney();
    });
  };

  const closePowerModal = () => setIsPowerModalOpen(false);
  const closeAgreementModal = () => setAgreementModalOpen(false);

  const handleCheckAgreement = () => {
    const formData = watch(); // Get current form data
    const todayDate = moment().format("DD-MM-YYYY");

    // Check required form fields
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.address ||
      !formData.passport_type_number ||
      !formData.contact ||
      !formData.date_of_birth ||
      !formData.tickets
    ) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Form",
        text: "Please fill in all required fields before agreeing to the terms.",
      });
      return false;
    }

    // Check Flight  details
    if (flightDetails.length > 0) {
      const incompleteFlightDetails = flightDetails.filter(
        (flight) =>
          !flight.airline ||
          !flight.flight_number ||
          !flight.airport_of_origin ||
          !flight.airport_of_destination ||
          !flight.date_of_travel
      );

      if (incompleteFlightDetails.length > 0) {
        Swal.fire({
          icon: "warning",
          title: "Incomplete Flight Details",
          text: "Please fill in all required flight details before agreeing to the terms.",
        });
        return false;
      }
    }

    // Check family members' details
    if (familyMembers.length > 0) {
      const incompleteFamilyMembers = familyMembers.filter(
        (member) =>
          !member.first_name ||
          !member.last_name ||
          !member.date_of_birth ||
          !member.passport_number ||
          !member.ticket_number
      );

      if (incompleteFamilyMembers.length > 0) {
        Swal.fire({
          icon: "warning",
          title: "Incomplete Travelers Details",
          text: "Please fill in all required travelers details before agreeing to the terms.",
        });
        return false;
      }
    }

    // If all checks pass, proceed with storing the data
    const dataToStore1 = {
      date: todayDate,
      clientName: `${formData.first_name} ${formData.last_name}`,
      clientAddress: formData.address,
      passportNumber: formData.passport_type_number,
      clientEmail: `${formData.email}`,
      bookingNumber: `${formData.contact}`,
      clientBirthDate: `${formData.date_of_birth}`,
      familyMembers: familyMembers, // Pass the familymember array here
      flightDetails: flightDetails,
      clientTicketNumber: formData.tickets,
    };

    setDataToStore(dataToStore1);

    console.log("hello", dataToStore);

    return true;
  };

  const handleCheckPowerOfAttorney = () => {
    return handleCheckAgreement();
  };

  const onSubmit = (data) => {
    //console.log("Img--->", selectedPassportSubClaim);
    //console.log("Form Data", data);

    if (!pdfBlob) {
      Swal.fire({
        icon: "error",
        title: "Agreement need to be signed",
      });
      return;
    }

    if (!country1) {
      setCountryValid(true);
      return;
    }

    // let timeValue = "";
    // if (selectedHour && selectedMinute) {
    //   timeValue = `${selectedHour}:${selectedMinute}`;

    // }

    // let delay = timeValue;
    const formData = new FormData();
    const notesArray = null; // or an empty array: []
    const updatedNotes = addCustomerNote(data.note, notesArray);

    const familyMembersData = familyMembers.map((member, index) => {
      const memberData = {
        first_name: member.first_name,
        last_name: member.last_name,
        date_of_birth: member.date_of_birth,
        passport_number: member.passport_number,
        tickets: member.ticket_number,
      };

      if (member.passport_file) {
        memberData.passport = member.passport_name;
        formData.append(member.passport_name, member.passport_file);
      }
      if (member.additional_doc) {
        memberData.additional_doc = member.additionalDoc_name;
        formData.append(member.additionalDoc_name, member.additional_doc);
      }

      return memberData;
    });
    const formattedFlightDetails = flightDetails.map((flight) => ({
      airline: flight.airline,
      flight_number: flight.flight_number,
      airport_of_origin: flight.airport_of_origin,
      airport_of_destination: flight.airport_of_destination,
      scheduled_arrival: `${flight.original_arrival_date}T${flight.original_arrival_time}`,
      scheduled_departure: `${flight.date_of_travel}T${flight.original_departure_time}`,
      date_of_travel: flight.date_of_travel,
      delayed:
        activeTab === "flight delay / flight cancellation"
          ? flight.delayed
          : false,
      cancelled:
        activeTab === "flight delay / flight cancellation"
          ? flight.cancelled
          : false,
      missed:
        activeTab === "flight delay / flight cancellation"
          ? flight.missed
          : false,
    }));

    const formattedActualFlightDetails = actualFlightDetails.map((flight) => ({
      actual_departure: `${flight.actual_date_of_travel}T${flight.actual_departure_time}`,
      actual_arrival: `${flight.actual_arrival_date}T${flight.actual_arrival_time}`,
      actual_airport_of_origin: flight.actual_airport_of_origin,
      actual_date_of_travel: flight.actual_date_of_travel,
      actual_airport_of_destination: flight.actual_airport_of_destination,
      actual_airline: flight.actual_airline,
      actual_flight_number: flight.actual_flight_number,
      provided_by_airline: flight.provided_by_airline,
      self_booked: flight.self_booked,
    }));

    // Now, you can use `formattedFlightDetails` to send to the API.

    //console.log("Family Details", familyMembersData);

    const formObject = {
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      passport_number: data.passport_type_number,
      nationality: country1,
      date_of_birth: data.date_of_birth,
      flight_details: formattedFlightDetails,
      actual_flight_details:
        activeTab === "flight delay / flight cancellation"
          ? formattedActualFlightDetails
          : null,
      status: "pending",
      source_of_claim: "flyandclaim",
      contact_no: data.contact,
      email_id: data.email,
      delay: delay !== "HH:MM" ? delay : null,
      note: updatedNotes,
      tickets: data.tickets,
      sub_claims: familyMembersData,
      airline_res_no: data.airline_res_no,
      envelope_id: envolpeId,
      type_of_claim:
        activeTab === "flight delay / flight cancellation"
          ? "flight delay / flight cancellation"
          : activeTab,
    };

    // Convert the form object to JSON
    const jsonFormObject = JSON.stringify(formObject);

    // Append the JSON object to the FormData
    formData.append("data", jsonFormObject);

    // Append file fields
    formData.append("boarding_pass", data.boarding_pass[0] || null);
    formData.append("additional_exp", null);
    formData.append("passport", data.passport[0] || null);

    if (selectedAdditionalFiles) {
      for (let i = 0; i < selectedAdditionalFiles.length; i++) {
        formData.append("document", selectedAdditionalFiles[i]);
      }
    } else {
      formData.append("document", null);
    }
    if (pdfBlob) {
      const pdfFile = new File([pdfBlob], "agreement.pdf", {
        type: "application/pdf",
      });
      console.log("INSIDE PDFFILE", pdfFile);

      formData.append("agreement", pdfFile);
    }

    if (powerPdf) {
      const pdfFile = new File([powerPdf], "power_of_attorney.pdf", {
        type: "application/pdf",
      });

      formData.append("power_of_attorney", pdfFile);
    }

    setbackdropopen(true);

    axios.post(`${base_url.api1}/tourapp/compensation_request`, formData).then(
      (response) => {
        //console.log(response);
        if (response.status === 200) {
          setbackdropopen(false);
          //console.log("77------>", response.data.data.unique_code);
          Swal.fire({
            icon: "info",
            title: "Save Your Claim ID",
            html: `Your Claim ID is: <strong>${response?.data?.data?.contact_no}</strong>. Please save it for future reference.`,
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/thank-you-page", {
              state: { isClaimed: 1 },
            });
          });

          document.getElementById("myForm").reset();
        } else {
          setbackdropopen(false);
        }
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "There was a problem connecting to the server. Please try again later.",
        });

        document.getElementById("myForm").reset();
        setbackdropopen(false);
      }
    );
  };

  return (
    <div style={{ backgroundColor: "#F5F6FA" }}>
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />
        {/* <div style={{ position: "relative", height: "25vh" }}>
          <img
            src={bannerbackground}
            alt="Header_Image"
            style={{ height: "100%", width: "100%" }}
          />
          <div className="overlaybg">
            <div style={{ postion: "absolute" }}>
              <h1
                style={{
                  width: "100%",
                  marginTop: "200px",
                  fontSize: "3.5rem",
                  textAlign: "center",
                  color: "#fff",
                  fontFamily: " Caveat,cursive",
                }}
              >
                {tourTitle ? tourTitle : null}
              </h1>
            </div>
          </div>
        </div> */}

        <div className="container">
          <div
            class="page-head d-flex justify-content-between p-4"
            style={{ marginTop: "1.3rem" }}
          >
            <ul class="breadcrums">
              <Link to="/" class="b-link">
                Home
              </Link>
              <span className="s_span ">&raquo;</span>
              {/* <Link to="#" class="b-link">
                Linsoa – Fly N Claim
              </Link>
              <span className="s_span ">&raquo;</span> */}
              <Link to="#" class="b-link active">
                File A Claim
              </Link>
            </ul>
          </div>
          <div className="tabs">
            {tabs.map((tab) => (
              <div
                key={tab.id}
                onClick={() => handleTabClick(tab.id)}
                className={`tab ${activeTab === tab.id ? "active-tab" : ""}`}
              >
                {tab.label}
              </div>
            ))}
          </div>
          <Row className="px-0">
            <Col lg={12} className="carosol_img" style={{ marginTop: "-22px" }}>
              <form
                id="myForm"
                onSubmit={handleSubmit(onSubmit)}
                className="login_container"
              >
                <p className="tour_head_text_3">Personal Details</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="First Name"
                        type="text"
                        {...register("first_name", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.first_name && (
                      <p className="errormessage">First Name is Required</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="Last Name"
                        type="text"
                        {...register("last_name", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.last_name && (
                      <p className="errormessage">Last Name is Required</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label>
                      Date Of Birth<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="date"
                        className="form-control"
                        {...register("date_of_birth", {
                          required: true,
                        })}
                        placeholder="DOB"
                      />
                      <i className="fa fa-calendar designi" />
                    </div>
                    {errors.date_of_birth && (
                      <p className="errormessage">Date Of Birth is Required</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      Home Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        {...register("address", {
                          required: "Home Address is Required",
                        })}
                        onChange={handleAddressInputChange}
                        placeholder="Enter Home Address"
                        className="search-input form-control"
                        type="text"
                        autoComplete="off"
                        onBlur={handleInputBlur}
                      />
                      <i
                        className="fa fa-map-marker home_icon"
                        style={{ position: "absolute", left: 15, top: 10 }}
                      />
                      {isOpenList && optionsList.length > 0 && (
                        <ul className="search-results">
                          {optionsList.map((option, index) => (
                            <li
                              key={index}
                              onClick={() => handleAddressChange(option)}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                      {errors.address && (
                        <p className="errormessage">{errors.address.message}</p>
                      )}
                    </div>
                  </div>
                  <Col md={4}>
                    <label>
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={mobile}
                        className="form-control"
                        placeholder="Mobile Number"
                        type="text"
                        {...register("contact", {
                          required: "Mobile Number is required",
                          validate: (value) =>
                            value.length === 10 ||
                            "Mobile Number must be 10 digits",
                        })}
                        onChange={handleMobileInputChange} // Move onChange handler here
                      />
                      <i className="fa fa-phone designi" />
                    </div>
                    {errors.contact && (
                      <p className="errormessage">{errors.contact.message}</p>
                    )}
                  </Col>

                  <div className="col-md-4">
                    <label>
                      Email Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="Email Address"
                        type="email"
                        {...register("email", {
                          required: "Email Address is Required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      <i className="fa fa-envelope designi" />
                    </div>
                    {errors.email && (
                      <p className="errormessage">{errors.email.message}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>
                      Passport Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="Passport number"
                        type="text"
                        {...register("passport_type_number", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-id-card designi" />
                    </div>
                    {errors.passport_type_number && (
                      <p className="errormessage">
                        Passport Number is Required
                      </p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Nationality<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <CountryDropdown
                        className="countrydropdown2 form-control underline-input"
                        id="tcountry"
                        defaultOptionLabel="- Select Country -"
                        value={country1}
                        onChange={(val) => {
                          setcountry(val);
                          setCountryValid(false);
                        }}
                      />
                      <i className="fas fa-globe designi" />
                    </div>
                    {countryValid ? (
                      <p className="errormessage">Country is Required</p>
                    ) : null}
                  </div>
                  <Col md={3}>
                    <label>
                      13 digit ticket Number
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={ticket}
                        className="form-control"
                        placeholder="Ticket Number"
                        type="text"
                        {...register("tickets", {
                          required: "Ticket Number is required",
                          validate: (value) =>
                            value.length === 13 ||
                            "Ticket Number must be 13 digits",
                        })}
                        onChange={handleTicketInputChange}
                      />
                      <i className="fa fa-ticket designi" />
                    </div>
                    {errors.tickets && (
                      <p className="errormessage">{errors.tickets.message}</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      Airline Reservation Number
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="Airline Reservation Number"
                        type="text"
                        {...register("airline_res_no", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-ticket designi" />
                    </div>
                    {errors.airline_res_no && (
                      <p className="errormessage">
                        Airline Reservation Number is Required
                      </p>
                    )}
                  </Col>

                  <div className="col-md-4">
                    <label>Boarding Pass</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("boarding_pass", {
                          required: false,
                        })}
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label>Passport</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("passport", {
                          required: false,
                        })}
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label>Additional Claims Documents</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        multiple
                        onChange={handleAdditionalFileChange}
                        className="form-control"
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {familyMembers.length > 0 &&
                    familyMembers.map((member, index) => (
                      <div className="col-12 " key={index}>
                        <h4>Traveler {index + 2}</h4>{" "}
                        {/* Display Traveler 1, Traveler 2, etc. */}
                        {/* First Name, Last Name, and Date Of Birth Fields */}
                        <div className="row">
                          <div className="col-md-3">
                            <label>
                              First Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                placeholder="First Name"
                                type="text"
                                value={member.first_name}
                                onChange={(e) =>
                                  handleInputChange(e, index, "first_name")
                                }
                                required
                              />
                              <i className="fa fa-user-o designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>
                              Last Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                placeholder="Last Name"
                                type="text"
                                value={member.last_name}
                                onChange={(e) =>
                                  handleInputChange(e, index, "last_name")
                                }
                                required
                              />
                              <i className="fa fa-user-o designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>
                              Date Of Birth{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                type="date"
                                value={member.date_of_birth}
                                onChange={(e) =>
                                  handleInputChange(e, index, "date_of_birth")
                                }
                                required
                              />
                              <i className="fa fa-calendar designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>Passport Number</label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                placeholder="Passport Number"
                                type="text"
                                value={member.passport_number}
                                onChange={(e) =>
                                  handleInputChange(e, index, "passport_number")
                                }
                                required
                              />
                              <i className="fa fa-id-card designi" />
                            </div>
                          </div>
                        </div>
                        {/* Passport Number, Ticket Number Fields */}
                        <div className="row mt-3">
                          <div className="col-md-3">
                            <label>
                              Ticket Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                placeholder="Ticket Number"
                                type="text"
                                value={member.ticket_number}
                                onChange={(e) =>
                                  handleTicketNumberChange(e, index)
                                }
                                required
                              />
                              {ticketNumberErrors[index] && (
                                <p className="errormessage">
                                  {ticketNumberErrors[index]}
                                </p>
                              )}
                              <i className="fa fa-ticket designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>Passport</label>
                            <div style={{ position: "relative" }}>
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) => handlePassportUpload(e, index)}
                              />
                              <i className="fa fa-file-image-o designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>Additional Document</label>
                            <div style={{ position: "relative" }}>
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) =>
                                  handleAdditionalUpload(e, index)
                                }
                              />
                              <i className="fa fa-file-image-o designi" />
                            </div>
                          </div>
                          <div className="col-md-2">
                            {/* Remove Button Next to Additional Document */}
                            <Button
                              color="danger"
                              size="sm" // Smaller button size
                              onClick={() => removeFamilyMember(index)}
                              style={{ marginTop: "30px" }}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <Button
                  color="primary"
                  onClick={addFamilyMember}
                  className="mt-3 mb-3"
                  style={{ width: "180px" }}
                >
                  Add Travelers
                </Button>

                <p className="tour_head_text_3">
                  {activeTab === "flight delay / flight cancellation"
                    ? "Flight Details(Originally Booked)"
                    : "Flight Details"}
                </p>
                <hr style={{ width: "20%" }} />
                {flightDetails.length > 0 &&
                  flightDetails.map((flight, index) => (
                    <div className="row" key={index}>
                      {activeTab === "flight delay / flight cancellation" && (
                        <h3>Flight {index + 1}</h3>
                      )}
                      <div className="col-md-4">
                        <label>
                          Airline<span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <Controller
                            name={`Airline_${index}`}
                            control={control}
                            rules={{ required: !flightDetails[index]?.airline }}
                            render={({ field }) => (
                              <AsyncSelect
                                {...field}
                                cacheOptions
                                defaultOptions={defaultAirlineOptions}
                                loadOptions={loadAirlineOptions}
                                value={
                                  flight.airline
                                    ? {
                                        label: flight.airline,
                                        value: flight.airline,
                                      }
                                    : null
                                }
                                placeholder="Select Airline"
                                onChange={(selectedOption) => {
                                  setFlightDetails((prevState) => {
                                    const updatedFlightDetails = [...prevState];
                                    updatedFlightDetails[index].hub_code =
                                      selectedOption?.hub_code || "";

                                    updatedFlightDetails[index].airline =
                                      selectedOption?.label || "";
                                    return updatedFlightDetails;
                                  });
                                  setTriggerIndex(index);
                                  setTriggerFrom("original");
                                  field.onChange(selectedOption?.label || "");
                                }}
                              />
                            )}
                          />
                          {errors[`Airline_${index}`] && (
                            <p className="errormessage">Airline is Required</p>
                          )}
                        </div>
                      </div>

                      {/* Flight Number */}
                      <div className="col-md-4">
                        <label>
                          Flight Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            className="form-control"
                            placeholder="Flight Number"
                            type="text"
                            value={flight.flight_number}
                            onChange={(e) => {
                              const valueWithoutSpaces = e.target.value.replace(
                                /\s+/g,
                                ""
                              );
                              const updatedFlightDetails = [...flightDetails];
                              updatedFlightDetails[index].flight_number =
                                valueWithoutSpaces;
                              setFlightDetails(updatedFlightDetails);
                              setTriggerIndex(index);
                              setTriggerFrom("original");
                            }}
                          />
                          <i className="fa fa-ticket designi" />
                        </div>
                        {errors[`flight_number_${index}`] && (
                          <p className="errormessage">
                            Flight Number is Required
                          </p>
                        )}
                      </div>

                      {/* Date Of Travel */}
                      <div className="col-md-4">
                        <label>
                          Travel Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            type="date"
                            className="form-control"
                            value={flight.date_of_travel}
                            onChange={(e) => {
                              const updatedFlightDetails = [...flightDetails];
                              updatedFlightDetails[index].date_of_travel =
                                e.target.value;

                              setFlightDetails(updatedFlightDetails);
                              setTriggerIndex(index);
                              setTriggerFrom("original");
                            }}
                          />
                          <i className="fa fa-calendar designi" />
                        </div>
                        {errors[`date_of_travel_${index}`] && (
                          <p className="errormessage">
                            Travel Date is Required
                          </p>
                        )}
                      </div>
                      {/* Airport of Origin */}
                      <div className="col-md-3">
                        <label>
                          Airport of Origin
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {flightOptions[index] &&
                        flightOptions[index].length > 0 ? (
                          <div style={{ position: "relative" }}>
                            <Controller
                              name={`airport_of_origin_${index}`}
                              control={control}
                              rules={{
                                required:
                                  !flightDetails[index]?.airport_of_origin,
                              }}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  defaultOptions={flightOptions[index].map(
                                    (option) => ({
                                      value: option.departure.airport,
                                      arrival: option.arrival.airport,
                                      departure: option.departure.airport,
                                      label: `${option.departure.airport} - ${option.arrival.airport}`,
                                    })
                                  )}
                                  value={
                                    flight.airport_of_origin
                                      ? {
                                          label: flight.airport_of_origin,
                                          value: flight.airport_of_origin,
                                        }
                                      : null
                                  }
                                  placeholder="Select Origin Airport"
                                  onChange={(selectedOption) => {
                                    console.log(
                                      "selectedOptions 1360",
                                      selectedOption
                                    );
                                    setTriggerIndex(null);
                                    const updatedFlightDetails = [
                                      ...flightDetails,
                                    ];
                                    updatedFlightDetails[
                                      index
                                    ].airport_of_origin =
                                      selectedOption?.departure || "";
                                    updatedFlightDetails[
                                      index
                                    ].airport_of_destination =
                                      selectedOption?.arrival || "";
                                    setFlightDetails(updatedFlightDetails);
                                    field.onChange(selectedOption?.label || "");
                                  }}
                                />
                              )}
                            />
                            {errors[`airport_of_origin_${index}`] && (
                              <p className="errormessage">
                                Origin Airport is Required
                              </p>
                            )}
                          </div>
                        ) : (
                          <div style={{ position: "relative" }}>
                            <Controller
                              name={`airport_of_origin_${index}`}
                              control={control}
                              defaultValue={flight.airport_of_origin}
                              rules={{
                                required:
                                  !flightDetails[index]?.airport_of_origin,
                              }}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  loadOptions={loadOptions}
                                  defaultOptions={defaultOptions}
                                  value={
                                    flight.airport_of_origin
                                      ? {
                                          label: flight.airport_of_origin,
                                          value: flight.airport_of_origin,
                                        }
                                      : null
                                  }
                                  placeholder="Select Origin Airport"
                                  onChange={(selectedOption) => {
                                    setTriggerIndex(null);
                                    const updatedFlightDetails = [
                                      ...flightDetails,
                                    ];
                                    updatedFlightDetails[
                                      index
                                    ].airport_of_origin =
                                      selectedOption?.label || "";
                                    setFlightDetails(updatedFlightDetails);
                                    field.onChange(selectedOption?.label || "");
                                  }}
                                />
                              )}
                            />
                            {errors[`airport_of_origin_${index}`] && (
                              <p className="errormessage">
                                Origin Airport is Required
                              </p>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-md-2">
                        <label>
                          Departure Time
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            type="time"
                            className="form-control"
                            value={flight.original_departure_time}
                            onChange={(e) => {
                              const updatedFlightDetails = [...flightDetails];
                              const updatedTime = e.target.value;
                              updatedFlightDetails[
                                index
                              ].original_departure_time = updatedTime;

                              setFlightDetails(updatedFlightDetails);
                              setTriggerIndex(null);
                            }}
                          />
                          <i className="fa fa-clock-o designi" />
                        </div>
                        {errors[`original_departure_time_${index}`] && (
                          <p className="errormessage">
                            Departure Time is Required
                          </p>
                        )}
                      </div>

                      {/* Airport of Destination */}
                      <div className="col-md-3">
                        <label>
                          Airport of Destination
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {flightOptions[index] &&
                        flightOptions[index].length > 0 ? (
                          <div style={{ position: "relative" }}>
                            <Controller
                              name={`airport_of_destination_${index}`}
                              control={control}
                              rules={{
                                required:
                                  !flightDetails[index]?.airport_of_destination,
                              }}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  defaultOptions={flightOptions[index].map(
                                    (option) => ({
                                      value: option.arrival.airport,
                                      arrival: option.arrival.airport,
                                      departure: option.departure.airport,
                                      label: `${option.departure.airport} - ${option.arrival.airport}`,
                                    })
                                  )}
                                  value={
                                    flight.airport_of_destination
                                      ? {
                                          label: flight.airport_of_destination,
                                          value: flight.airport_of_destination,
                                        }
                                      : null
                                  }
                                  placeholder="Select Origin Airport"
                                  onChange={(selectedOption) => {
                                    console.log(
                                      "selectedOptions 1466",
                                      selectedOption
                                    );
                                    setTriggerIndex(null);
                                    const updatedFlightDetails = [
                                      ...flightDetails,
                                    ];
                                    updatedFlightDetails[
                                      index
                                    ].airport_of_origin =
                                      selectedOption?.departure || "";
                                    updatedFlightDetails[
                                      index
                                    ].airport_of_destination =
                                      selectedOption?.arrival || "";
                                    setFlightDetails(updatedFlightDetails);
                                    field.onChange(selectedOption?.label || "");
                                  }}
                                />
                              )}
                            />
                            {errors[`airport_of_destination_${index}`] && (
                              <p className="errormessage">
                                Destination Airport is Required
                              </p>
                            )}
                          </div>
                        ) : (
                          <div style={{ position: "relative" }}>
                            <Controller
                              name={`airport_of_destination_${index}`}
                              control={control}
                              defaultValue={flight.airport_of_destination}
                              rules={{
                                required:
                                  !flightDetails[index]?.airport_of_destination,
                              }}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  loadOptions={loadOptions}
                                  defaultOptions={defaultOptions}
                                  value={
                                    flight.airport_of_destination
                                      ? {
                                          label: flight.airport_of_destination,
                                          value: flight.airport_of_destination,
                                        }
                                      : null
                                  }
                                  placeholder="Select Destination Airport"
                                  onChange={(selectedOption) => {
                                    setTriggerIndex(null);
                                    const updatedFlightDetails = [
                                      ...flightDetails,
                                    ];
                                    updatedFlightDetails[
                                      index
                                    ].airport_of_destination =
                                      selectedOption?.label || "";
                                    setFlightDetails(updatedFlightDetails);
                                    field.onChange(selectedOption?.label || "");
                                  }}
                                />
                              )}
                            />
                            {errors[`airport_of_destination_${index}`] && (
                              <p className="errormessage">
                                Destination Airport is Required
                              </p>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-md-2">
                        <label>
                          Arrival Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            type="date"
                            className="form-control"
                            name="original_arrival_date"
                            value={flight.original_arrival_date}
                            onChange={(e) => {
                              const updatedFlightDetails = [...flightDetails];
                              const updatedDate = e.target.value;
                              updatedFlightDetails[
                                index
                              ].original_arrival_date = updatedDate;

                              setFlightDetails(updatedFlightDetails);
                              setTriggerIndex(null);
                            }}
                          />
                          <i className="fa fa-calendar designi" />
                        </div>
                        {errors[`original_arrival_date_${index}`] && (
                          <p className="errormessage">
                            Arrival Date is Required
                          </p>
                        )}
                      </div>

                      <div className="col-md-2">
                        <label>
                          Arrival Time
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <div style={{ display: "flex", gap: "20px" }}>
                            <input
                              type="time"
                              className="form-control"
                              name="original_arrival_time"
                              value={flight.original_arrival_time}
                              onChange={(e) => {
                                const updatedFlightDetails = [...flightDetails];
                                const updatedTime = e.target.value;
                                updatedFlightDetails[
                                  index
                                ].original_arrival_time = updatedTime;
                                setFlightDetails(updatedFlightDetails);
                                setTriggerIndex(null);
                              }}
                            />
                            <i className="fa fa-clock-o designi" />
                            {flightDetails.length > 1 && (
                              <div
                                style={{
                                  backgroundColor: "none",
                                  color: "red",
                                  cursor: "pointer",
                                  marginLeft: "2px",
                                }}
                                onClick={() => removeFlightDetails(index)}
                              >
                                <RemoveCircleOutline />
                              </div>
                            )}
                          </div>
                        </div>
                        {errors[`original_arrival_time_${index}`] && (
                          <p className="errormessage">
                            Arrival Time is Required
                          </p>
                        )}
                      </div>

                      {/* Delayed and Cancelled checkboxes in one column with spacing */}
                      {activeTab === "flight delay / flight cancellation" && (
                        <div className="col-md-6 ">
                          <div className="d-flex flex-wrap gap-3">
                            {/* Delayed Checkbox */}
                            <div className="form-check d-flex align-items-center">
                              <label className="form-check-label me-5 mb-0">
                                Delayed
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={flight.delayed}
                                onChange={() =>
                                  setFlightDetails((prevState) => {
                                    const updatedFlightDetails = [...prevState];
                                    updatedFlightDetails[index] = {
                                      ...updatedFlightDetails[index],
                                      delayed: !flight.delayed,
                                      cancelled: false, // Explicitly uncheck Cancelled
                                      missed: false, // Explicitly uncheck Missed
                                    };
                                    return updatedFlightDetails;
                                  })
                                }
                              />
                            </div>

                            {/* Cancelled Checkbox */}
                            <div className="form-check d-flex align-items-center">
                              <label className="form-check-label me-5 mb-0">
                                Cancelled
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={flight.cancelled}
                                onChange={() =>
                                  setFlightDetails((prevState) => {
                                    const updatedFlightDetails = [...prevState];
                                    updatedFlightDetails[index] = {
                                      ...updatedFlightDetails[index],
                                      cancelled: !flight.cancelled,
                                      delayed: false, // Explicitly uncheck Delayed
                                      missed: false, // Explicitly uncheck Missed
                                    };
                                    return updatedFlightDetails;
                                  })
                                }
                              />
                            </div>

                            {/* Missed Checkbox */}
                            <div className="form-check d-flex align-items-center">
                              <label className="form-check-label me-5 mb-0">
                                Missed
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={flight.missed}
                                onChange={() =>
                                  setFlightDetails((prevState) => {
                                    const updatedFlightDetails = [...prevState];
                                    updatedFlightDetails[index] = {
                                      ...updatedFlightDetails[index],
                                      missed: !flight.missed,
                                      delayed: false, // Explicitly uncheck Delayed
                                      cancelled: false, // Explicitly uncheck Cancelled
                                    };
                                    return updatedFlightDetails;
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                <Button
                  color="primary"
                  onClick={addFlightDetails}
                  className="mt-3 mb-3"
                  style={{ width: "200px" }}
                >
                  Additional Flight
                </Button>
                {activeTab === "flight delay / flight cancellation" && (
                  <>
                    <p className="tour_head_text_3">
                      Flight Details(Actual Flight)
                    </p>
                    <hr style={{ width: "20%" }} />
                    {actualFlightDetails.length > 0 &&
                      actualFlightDetails.map((flight, index) => (
                        <div className="row">
                          <h3>Flight {index + 1}</h3>

                          <div className="col-md-4">
                            <label>
                              Airline<span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <Controller
                                name={`Actual_Airline_${index}`}
                                control={control}
                                rules={{
                                  required:
                                    !actualFlightDetails[index]?.actual_airline,
                                }} // Mandatory for the first flight
                                render={({ field }) => (
                                  <AsyncSelect
                                    {...field}
                                    cacheOptions
                                    defaultOptions={defaultAirlineOptions}
                                    loadOptions={loadAirlineOptions}
                                    value={
                                      flight.actual_airline
                                        ? {
                                            label: flight.actual_airline,
                                            value: flight.actual_airline,
                                          }
                                        : null
                                    }
                                    placeholder="Select Airline"
                                    onChange={(selectedOption) => {
                                      setActualFlightDetails((prevState) => {
                                        const updatedFlightDetails = [
                                          ...prevState,
                                        ];
                                        updatedFlightDetails[
                                          index
                                        ].actual_hub_code =
                                          selectedOption?.hub_code || "";

                                        updatedFlightDetails[
                                          index
                                        ].actual_airline =
                                          selectedOption?.label || "";
                                        return updatedFlightDetails;
                                      });
                                      setTriggerIndex(index);
                                      setTriggerFrom("actual");
                                      field.onChange(
                                        selectedOption?.label || ""
                                      );
                                    }}
                                  />
                                )}
                              />
                              {errors[`Actual_Airline_${index}`] && (
                                <p className="errormessage">
                                  Airline is Required
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Flight Number */}
                          <div className="col-md-4">
                            <label>
                              Flight Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                placeholder="Flight Number"
                                type="text"
                                value={flight.actual_flight_number}
                                onChange={(e) => {
                                  const valueWithoutSpaces =
                                    e.target.value.replace(/\s+/g, "");
                                  const updatedFlightDetails = [
                                    ...actualFlightDetails,
                                  ];
                                  updatedFlightDetails[
                                    index
                                  ].actual_flight_number = valueWithoutSpaces;
                                  setActualFlightDetails(updatedFlightDetails);
                                  setTriggerIndex(index);
                                  setTriggerFrom("actual");
                                }}
                              />
                              <i className="fa fa-ticket designi" />
                            </div>
                            {errors[`flight_number_${index}`] && (
                              <p className="errormessage">
                                Flight Number is Required
                              </p>
                            )}
                          </div>

                          {/* Date Of Travel */}
                          <div className="col-md-4">
                            <label>
                              Travel Date<span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type="date"
                                className="form-control"
                                value={flight.actual_date_of_travel}
                                onChange={(e) => {
                                  const updatedFlightDetails = [
                                    ...actualFlightDetails,
                                  ];
                                  updatedFlightDetails[
                                    index
                                  ].actual_date_of_travel = e.target.value;

                                  setActualFlightDetails(updatedFlightDetails);
                                  setTriggerIndex(index);
                                  setTriggerFrom("actual");
                                }}
                              />
                              <i className="fa fa-calendar designi" />
                            </div>
                            {errors[`date_of_travel_${index}`] && (
                              <p className="errormessage">
                                Travel Date is Required
                              </p>
                            )}
                          </div>
                          {/* Airport of Origin */}
                          <div className="col-md-3">
                            <label>
                              Airport of Origin
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {flightOptions[index] &&
                            flightOptions[index].length > 0 ? (
                              <div style={{ position: "relative" }}>
                                <Controller
                                  name={`actual_airport_of_origin_${index}`}
                                  control={control}
                                  rules={{
                                    required:
                                      !actualFlightDetails[index]
                                        ?.actual_airport_of_origin,
                                  }}
                                  render={({ field }) => (
                                    <AsyncSelect
                                      {...field}
                                      cacheOptions
                                      defaultOptions={flightOptions[index].map(
                                        (option) => ({
                                          value: option.departure.airport,
                                          arrival: option.arrival.airport,
                                          departure: option.departure.airport,
                                          label: `${option.departure.airport} - ${option.arrival.airport}`,
                                        })
                                      )}
                                      value={
                                        flight.actual_airport_of_origin
                                          ? {
                                              label:
                                                flight.actual_airport_of_origin,
                                              value:
                                                flight.actual_airport_of_origin,
                                            }
                                          : null
                                      }
                                      placeholder="Select Origin Airport"
                                      onChange={(selectedOption) => {
                                        console.log(
                                          "selectedOptions 1360",
                                          selectedOption
                                        );
                                        setTriggerIndex(null);
                                        const updatedFlightDetails = [
                                          ...actualFlightDetails,
                                        ];
                                        updatedFlightDetails[
                                          index
                                        ].actual_airport_of_origin =
                                          selectedOption?.label || "";
                                        // updatedFlightDetails[
                                        //   index
                                        // ].actual_airport_of_destination =
                                        //   selectedOption?.arrival || "";
                                        setActualFlightDetails(
                                          updatedFlightDetails
                                        );
                                        field.onChange(
                                          selectedOption?.label || ""
                                        );
                                      }}
                                    />
                                  )}
                                />
                                {errors[
                                  `actual_airport_of_origin_${index}`
                                ] && (
                                  <p className="errormessage">
                                    Origin Airport is Required
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div style={{ position: "relative" }}>
                                <Controller
                                  name={`actual_airport_of_origin_${index}`}
                                  control={control}
                                  rules={{
                                    required:
                                      !actualFlightDetails[index]
                                        ?.actual_airport_of_origin,
                                  }}
                                  render={({ field }) => (
                                    <AsyncSelect
                                      {...field}
                                      cacheOptions
                                      loadOptions={loadOptions}
                                      defaultOptions={defaultOptions}
                                      value={
                                        flight.actual_airport_of_origin
                                          ? {
                                              label:
                                                flight.actual_airport_of_origin,
                                              value:
                                                flight.actual_airport_of_origin,
                                            }
                                          : null
                                      }
                                      placeholder="Select Origin Airport"
                                      onChange={(selectedOption) => {
                                        setTriggerIndex(null);
                                        const updatedFlightDetails = [
                                          ...actualFlightDetails,
                                        ];
                                        updatedFlightDetails[
                                          index
                                        ].actual_airport_of_origin =
                                          selectedOption?.label || "";
                                        setActualFlightDetails(
                                          updatedFlightDetails
                                        );
                                        field.onChange(
                                          selectedOption?.label || ""
                                        );
                                      }}
                                    />
                                  )}
                                />
                                {errors[
                                  `actual_airport_of_origin_${index}`
                                ] && (
                                  <p className="errormessage">
                                    Origin Airport is Required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label>
                              Departure Time
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type="time"
                                className="form-control"
                                value={flight.actual_departure_time}
                                onChange={(e) => {
                                  const updatedFlightDetails = [
                                    ...actualFlightDetails,
                                  ];
                                  const updatedTime = e.target.value;

                                  updatedFlightDetails[
                                    index
                                  ].actual_departure_time = updatedTime;
                                  setActualFlightDetails(updatedFlightDetails);
                                  setTriggerIndex(null);
                                }}
                              />
                              <i className="fa fa-clock-o designi" />
                            </div>
                            {errors[`actual_departure_time_${index}`] && (
                              <p className="errormessage">
                                Departure Time is Required
                              </p>
                            )}
                          </div>
                          {/* Airport of Destination */}
                          <div className="col-md-3">
                            <label>
                              Airport of Destination
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {flightOptions[index] &&
                            flightOptions[index].length > 0 ? (
                              <div style={{ position: "relative" }}>
                                <Controller
                                  name={`actual_airport_of_destination_${index}`}
                                  control={control}
                                  rules={{
                                    required:
                                      !actualFlightDetails[index]
                                        ?.actual_airport_of_destination,
                                  }}
                                  render={({ field }) => (
                                    <AsyncSelect
                                      {...field}
                                      cacheOptions
                                      defaultOptions={flightOptions[index].map(
                                        (option) => ({
                                          value: option.arrival.airport,
                                          arrival: option.arrival.airport,
                                          departure: option.departure.airport,
                                          label: `${option.departure.airport} - ${option.arrival.airport}`,
                                        })
                                      )}
                                      value={
                                        flight.actual_airport_of_destination
                                          ? {
                                              label:
                                                flight.actual_airport_of_destination,
                                              value:
                                                flight.actual_airport_of_destination,
                                            }
                                          : null
                                      }
                                      placeholder="Select Origin Airport"
                                      onChange={(selectedOption) => {
                                        console.log(
                                          "selectedOptions 1466",
                                          selectedOption
                                        );
                                        setTriggerIndex(null);
                                        const updatedFlightDetails = [
                                          ...actualFlightDetails,
                                        ];
                                        // updatedFlightDetails[
                                        //   index
                                        // ].actual_airport_of_origin =
                                        //   selectedOption?.departure || "";
                                        updatedFlightDetails[
                                          index
                                        ].actual_airport_of_destination =
                                          selectedOption?.label || "";
                                        setActualFlightDetails(
                                          updatedFlightDetails
                                        );
                                        field.onChange(
                                          selectedOption?.label || ""
                                        );
                                      }}
                                    />
                                  )}
                                />
                                {errors[
                                  `actual_airport_of_destination_${index}`
                                ] && (
                                  <p className="errormessage">
                                    Destination Airport is Required
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div style={{ position: "relative" }}>
                                <Controller
                                  name={`actual_airport_of_destination_${index}`}
                                  control={control}
                                  defaultValue={
                                    flight.actual_airport_of_destination
                                  }
                                  rules={{
                                    required:
                                      !actualFlightDetails[index]
                                        ?.actual_airport_of_destination,
                                  }}
                                  render={({ field }) => (
                                    <AsyncSelect
                                      {...field}
                                      cacheOptions
                                      loadOptions={loadOptions}
                                      defaultOptions={defaultOptions}
                                      value={
                                        flight.actual_airport_of_destination
                                          ? {
                                              label:
                                                flight.actual_airport_of_destination,
                                              value:
                                                flight.actual_airport_of_destination,
                                            }
                                          : null
                                      }
                                      placeholder="Select Destination Airport"
                                      onChange={(selectedOption) => {
                                        setTriggerIndex(null);
                                        const updatedFlightDetails = [
                                          ...actualFlightDetails,
                                        ];
                                        updatedFlightDetails[
                                          index
                                        ].actual_airport_of_destination =
                                          selectedOption?.label || "";
                                        setActualFlightDetails(
                                          updatedFlightDetails
                                        );
                                        field.onChange(
                                          selectedOption?.label || ""
                                        );
                                      }}
                                    />
                                  )}
                                />
                                {errors[
                                  `actual_airport_of_destination_${index}`
                                ] && (
                                  <p className="errormessage">
                                    Destination Airport is Required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>

                          <div className="col-md-2">
                            <label>
                              Arrival Date
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type="date"
                                className="form-control"
                                value={flight.actual_arrival_date}
                                onChange={(e) => {
                                  const updatedFlightDetails = [
                                    ...actualFlightDetails,
                                  ];
                                  const updatedDate = e.target.value;
                                  updatedFlightDetails[
                                    index
                                  ].actual_arrival_date = updatedDate;

                                  setActualFlightDetails(updatedFlightDetails);
                                  setTriggerIndex(null);
                                }}
                              />
                              <i className="fa fa-calendar designi" />
                            </div>
                            {errors[`actual_arrival_date_${index}`] && (
                              <p className="errormessage">
                                Arrival Date is Required
                              </p>
                            )}
                          </div>

                          <div className="col-md-2">
                            <label>
                              Arrival Time
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <div className="d-flex" style={{ gap: "20px" }}>
                                <input
                                  type="time"
                                  className="form-control"
                                  value={flight.actual_arrival_time}
                                  onChange={(e) => {
                                    const updatedFlightDetails = [
                                      ...actualFlightDetails,
                                    ];
                                    const updatedTime = e.target.value;

                                    updatedFlightDetails[
                                      index
                                    ].actual_arrival_time = updatedTime;
                                    // console.log("1986--->", updatedTime);
                                    setActualFlightDetails(
                                      updatedFlightDetails
                                    );
                                    setTriggerIndex(null);
                                  }}
                                />
                                <i className="fa fa-clock-o designi" />
                                {actualFlightDetails.length > 1 && (
                                  <div
                                    style={{
                                      backgroundColor: "none",
                                      color: "red",
                                      cursor: "pointer",
                                      marginLeft: "2px",
                                    }}
                                    onClick={() =>
                                      removeActualFlightDetails(index)
                                    }
                                  >
                                    <RemoveCircleOutline />
                                  </div>
                                )}
                              </div>
                            </div>
                            {errors[`actual_arrival_time_${index}`] && (
                              <p className="errormessage">
                                Arrival Time is Required
                              </p>
                            )}
                          </div>

                          {activeTab ===
                            "flight delay / flight cancellation" && (
                            <div className="col-md-6 d-flex justify-content-start align-items-center">
                              <div className="d-flex flex-wrap gap-2">
                                {/* Provided by Airline */}
                                <div>
                                  <h5 className="d-flex align-items-center">
                                    Provided by
                                  </h5>
                                </div>
                                <div className="d-flex">
                                  {" "}
                                  <div className="form-check d-flex align-items-center">
                                    <label className="form-check-label me-5 mb-0">
                                      Airline
                                    </label>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={flight.provided_by_airline}
                                      onChange={() =>
                                        setActualFlightDetails((prevState) => {
                                          const updatedFlightDetails = [
                                            ...prevState,
                                          ];
                                          updatedFlightDetails[index] = {
                                            ...updatedFlightDetails[index],
                                            provided_by_airline: true, // Set to true when checked
                                            self_booked: false, // Unselect self_booked if provided_by_airline is selected
                                          };
                                          return updatedFlightDetails;
                                        })
                                      }
                                    />
                                  </div>
                                  {/* Self Booked */}
                                  <div className="form-check d-flex align-items-center">
                                    <label className="form-check-label me-5 mb-0">
                                      Self Booked
                                    </label>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={flight.self_booked}
                                      onChange={() =>
                                        setActualFlightDetails((prevState) => {
                                          const updatedFlightDetails = [
                                            ...prevState,
                                          ];
                                          updatedFlightDetails[index] = {
                                            ...updatedFlightDetails[index],
                                            self_booked: true, // Set to true when checked
                                            provided_by_airline: false, // Unselect provided_by_airline if self_booked is selected
                                          };
                                          return updatedFlightDetails;
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </>
                )}

                {activeTab === "flight delay / flight cancellation" && (
                  <>
                    {" "}
                    <Button
                      color="primary"
                      onClick={addActualFlightDetails}
                      className="mt-3 mb-3"
                      style={{ width: "200px" }}
                    >
                      Additional Flight
                    </Button>
                    <Row>
                      <div className="col-md-4">
                        <label>
                          Delay (hours:minutes)
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <div style={{ display: "flex", gap: "20px" }}>
                            <input
                              type="text"
                              className="form-control"
                              value={delay.split(":")[0]} // Extract hours
                              readOnly
                              style={{ width: "40%" }}
                              placeholder="HH"
                            />
                            <input
                              type="text"
                              className="form-control"
                              value={delay.split(":")[1]} // Extract minutes
                              readOnly
                              style={{ width: "40%" }}
                              placeholder="MM"
                            />
                          </div>
                        </div>
                      </div>
                    </Row>
                  </>
                )}
                {/* <p className="tour_head_text_3">Upload Documents</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  <div className="col-md-4">
                    <label>Boarding Pass</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("boarding_pass", {
                          required: false,
                        })}
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label>Passport</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("passport", {
                          required: false,
                        })}
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label>Additional Claims Documents</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        multiple
                        onChange={handleAdditionalFileChange}
                        className="form-control"
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-8">
                    <label>
                      <strong> Note to the Attorneys : </strong>Explain Your
                      Claim in Details
                    </label>
                    <textarea
                      className="form-control form-control-sm"
                      {...register("note", {
                        required: false,
                      })}
                      style={{
                        minHeight: "150px",
                        fontSize: "18px",
                      }}
                      id="note"
                    />
                  </div>
                </div>
                <div
                  className="d-flex flex-column justify-content-start "
                  style={{ gap: "15px" }}
                >
                  <div>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="termsCheckbox"
                      checked={isTermsChecked}
                      onChange={handleTermsChange}
                    />
                    <label
                      className="form-check-label mt-2"
                      htmlFor="termsCheckbox"
                      style={{ marginLeft: "12px" }}
                    >
                      I agree to the{" "}
                      <a
                        href="https://api.linsoatours.com/Images/other_document/FLT_Airline_claims_-_TC_1.pdf"
                        target="_blank"
                        onClick={handleTermsLinkClick}
                      >
                        Terms and Conditions
                      </a>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="agreementCheckbox"
                      checked={isAgreementChecked}
                      onChange={(e) => {
                        if (handleCheckAgreement()) {
                          handleAgreementChange(e);
                        }
                      }}
                    />
                    <label
                      className="form-check-label mt-2"
                      htmlFor="agreementCheckbox"
                      style={{ marginLeft: "12px" }}
                    >
                      I agree to the{" "}
                      <Link
                        onClick={() => {
                          if (handleCheckAgreement()) {
                            openAgreementModal();
                          }
                        }}
                      >
                        Agreement
                      </Link>
                    </label>
                    {signatureCaptured && (
                      <Button
                        onClick={() => {
                          if (pdfBlob) {
                            const url = URL.createObjectURL(pdfBlob);
                            window.open(url, "_blank");
                          }
                        }}
                        color="primary"
                        style={{
                          width: "200px",
                          marginLeft: "15px",
                        }}
                      >
                        View Agreement
                      </Button>
                    )}
                  </div>
                  {familyMembers.length > 0 && (
                    <div>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="powerOfAttorneyCheckbox"
                        checked={isPowerOfAttorneyChecked}
                        onChange={(e) => {
                          if (handleCheckPowerOfAttorney()) {
                            handlePowerOfAttorneyChange(e);
                          }
                        }}
                      />
                      <label
                        className="form-check-label mt-2"
                        htmlFor="powerOfAttorneyCheckbox"
                        style={{ marginLeft: "12px" }}
                      >
                        I agree to the{" "}
                        <Link
                          onClick={() => {
                            if (handleCheckPowerOfAttorney()) {
                              openPowerOfAttorneyModal();
                            }
                          }}
                        >
                          Power of Attorney
                        </Link>
                      </label>
                    </div>
                  )}
                </div>
                <AgreementModal
                  isOpen={isAgreementModalOpen}
                  onRequestClose={closeAgreementModal}
                  onSignatureAdded={handleSignatureAdded}
                  onPdfGenerated={handlePdfGenerated}
                  setEnvelopeId={setEnvelopeId}
                  envolpeId={envolpeId}
                  signUrl={signUrl}
                  setSignUrl={setSignUrl}
                  setIsDocusignModalOpen={setIsDocusignModalOpen}
                  setIsFetching={setIsFetching}
                  setDocumentFetched={setDocumentFetched}
                  agreementDetails={dataToStore}
                  activeTab={activeTab}
                />
                {/* <PowerModal
                  isOpen={isPowerModalOpen}
                  onRequestClose={closePowerModal}
                  agreementDetails={dataToStore}
                  activeTab={activeTab}
                /> */}
                <DocuSignModal
                  isOpen={isDocuSignModalOpen}
                  onRequestClose={() => setIsDocusignModalOpen(false)}
                  signUrl={signUrl}
                  setSignUrl={setSignUrl}
                  handleIframeClose={handleIframeClose}
                />
                <div style={{ padding: "0 20px", marginBottom: "50px" }}>
                  <div>
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <Button
                        type="submit"
                        color="primary"
                        style={{
                          padding: "10px 40px",
                          marginLeft: "5px",
                          float: "right",
                          borderRadius: "40px",
                        }}
                        disabled={!isFormValid}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
          <div className="tourdetails_grid"></div>
        </div>
        <div style={{ display: "none" }}>
          {dataToStore !== null && (
            <div ref={powerRef}>
              <PowerOfAttorney formData={dataToStore} activeTab={activeTab} />
            </div>
          )}
        </div>

        {/* <FooterNew /> */}
      </React.Fragment>
    </div>
  );
};

export default ClaimForm;
