import React from "react";
import { ListGroup } from "react-bootstrap";
import moment from "moment";

const PrintNote = ({ claimId, note ,claimData,flightDetails,actualFlightDetails,otherDoc,familyMembers }) => {
  const messages = note || [];

  return (
    <>

    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        color: "#555",
        border: "1px solid #ccc",
        borderRadius: "8px",
        marginBottom: "10px",
      }}
    >

        <div style={{ borderBottom: "4px solid #999a99", paddingBottom: "10px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "5px",
                gap: "10px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <h1
                  style={{
                    margin: "0",
                    fontSize: "26px",
                    color: "#2c3e50",
                    fontFamily: "Arial, sans-serif",
                    paddingRight: "50px",
                  }}
                >
                  Claim Details
                </h1>
              </div>
      
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {/* <img
                  src="./symbol-travel.jpg"
                  alt="Flight Icon"
                  style={{ width: "100px", height: "100px" }}
                /> */}
                <h1
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    color: "#2c3e50",
                    fontFamily: "Arial, sans-serif",
                  }}
                >
                  Fly & Claim
                </h1>
              </div>
            </div>
      
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div style={{ fontSize: "13px", color: "#666", marginTop: "5px" }}>
                {/* ✈️ DALLAS, TX, FT INT'L TERMINAL 1 */}
              </div>
              <div style={{ fontSize: "13px", color: "#666", marginTop: "5px" }}>
                {/* ➔ LONDON, UNITED KINGDOM, TERMINAL 3 */}
              </div>
            </div>
          </div>

          <div style={{ marginTop: "40px" }}>
            <h2
              style={{
                fontSize: "17px",
                marginBottom: "10px",
                color: "#555",
                paddingBottom: "5px",
              }}
            >
              PASSENGER INFORMATION
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                maxWidth: "700px",
              }}
            >
              <div style={{ flex: 1 }}>
                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Name of Traveller
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.first_name} {claimData.last_name}
                  </p>
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Claim ID
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                  {claimData.contact_no}
                  </p>
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Date of birth
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.date_of_birth}
                  </p>
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Status
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.status}
                  </p>
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Ticket number
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.tickets}
                  </p>
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Passport no
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.passport_number}
                  </p>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Email
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.email_id}
                  </p>
                </div>

                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Address
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.address}
                    <br />
                  </p>
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Nationality
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.nationality}
                  </p>
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Airline reservation number
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.airline_res_no}
                  </p>
                </div>
                <div>
                  <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                    Delay
                  </p>
                  <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
                    {claimData.delay}
                  </p>
                </div>
              </div>
            </div>
          </div>;

<div>
  <h2 style={{ fontSize: "17px", marginBottom: "10px", color: "#555", paddingBottom: "3px" }}>
    Flight details {claimData.type_of_claim == "general claims" ? "": "(Originally Booked)"} :
  </h2>
  {flightDetails.map((flight, index) => (
    <div key={flight.id}>
      <h2 style={{ fontSize: "17px", marginBottom: "10px", color: "#555", paddingBottom: "3px" }}>
        Flight {index + 1}: {/* Display index starting from 1 */}
      </h2>
      <div style={{ display: "flex", borderTop: "3px solid #999a99" }}>
        <div style={{ flex: 1, backgroundColor: "#fff4dc", padding: "15px", borderRight: "1px solid #ddd" }}>
          <p style={{ margin: 0, fontWeight: "bold", fontSize: "16.7px" }}>Flight No.</p>
          <p style={{ margin: "5px 0", color: "#666" }}>{flight.flight_number}</p>
          <p style={{ margin: 0, fontWeight: "bold", fontSize: "13px", marginTop: "10px" }}>Airline</p>
          <p style={{ margin: "5px 0", color: "#666", fontSize: "12px" }}>{flight.airline}</p>
          <p style={{ margin: 0, fontWeight: "bold", fontSize: "13px", marginTop: "10px" }}>Airport of origin</p>
          <p style={{ margin: "5px 0", color: "#666", fontSize: "12px" }}>{flight.airport_of_origin}</p>
        </div>
        <div style={{ flex: 2, padding: "0 15px", marginTop: "5px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
            <div style={{ width: "45%", wordWrap: "break-word" }}>
              <p style={{ margin: 0, fontWeight: "bold", fontSize: "15px" }}>Departure</p>
              <p style={{ margin: "5px 0", color: "#666" }}>{flight?.original_departure_time}
              </p>
            </div>
            <div style={{ width: "45%", wordWrap: "break-word" }}>
              <p style={{ margin: 0, fontWeight: "bold", fontSize: "15px" }}>Travel Date</p>
              <p style={{ margin: "5px 0", color: "#666" }}>{flight.date_of_travel
                              ? moment.parseZone(flight.date_of_travel).format("YYYY-MM-DD")
                              : ""}</p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
            <div style={{ width: "45%", wordWrap: "break-word" }}>
              <p style={{ margin: 0, fontWeight: "bold", fontSize: "15px" }}>Airport of destination</p>
              <p style={{ margin: "5px 0", color: "#666" }}>{flight.airport_of_destination}</p>
            </div>
            <div style={{ width: "45%", wordWrap: "break-word" }}>
              <p style={{ margin: 0, fontWeight: "bold", fontSize: "15px" }}>Arrival Date</p>
              <p style={{ margin: "5px 0", color: "#666" }}>{flight.original_arrival_time}</p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
            <div style={{ width: "30%", wordWrap: "break-word" }}>
              <p style={{ margin: 0, fontWeight: "bold", fontSize: "13px" }}>Delayed</p>
              <p style={{ margin: "5px 0", color: "#666" }}>{flight.delayed ? "Yes" : "No"}</p>
            </div>
            <div style={{ width: "30%", wordWrap: "break-word" }}>
              <p style={{ margin: 0, fontWeight: "bold", fontSize: "13px" }}>Cancelled</p>
              <p style={{ margin: "5px 0", color: "#666" }}>{flight.cancelled ? "Yes" : "No"}</p>
            </div>
            <div style={{ width: "30%", wordWrap: "break-word" }}>
              <p style={{ margin: 0, fontWeight: "bold", fontSize: "13px" }}>Missed</p>
              <p style={{ margin: "5px 0", color: "#666" }}>{flight.missed ? "Yes" : "No"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>

{actualFlightDetails.length > 0 && (
<div style={{ borderBottom: "4px solid #999a99", paddingBottom: "10px" }}>
  <h1
    style={{
      marginBottom: "5px",
      fontSize: "17px",
      color: "#555",
      fontFamily: "Arial, sans-serif",
      marginTop: "50px",
    }}
  >
    Flight details (Actual Flight)
  </h1>

  {actualFlightDetails.map((af, index) => (
    <div key={af.id}>
      <h2
        style={{
          fontSize: "17px",
          marginBottom: "10px",
          color: "#555",
          paddingBottom: "3px",
        }}
      >
        Flight {index + 1}:
      </h2>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          borderBottom: "3px solid #999a99"
        }}
      >
        <div style={{ fontSize: "13px", color: "#666", marginTop: "5px" }}>
          ✈️ &nbsp; {af.actual_airport_of_origin}
        </div>
        <div style={{ fontSize: "13px", color: "#666", marginTop: "5px" }}>
          ➔ &nbsp; {af.actual_airport_of_destination}
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "20px" }}>
        <div
          style={{
            flex: 1,
            backgroundColor: "#fff4dc",
            padding: "15px",
            borderRight: "1px solid #ddd",
          }}
        >
          <p style={{ margin: "0", fontWeight: "bold", fontSize: "16.7px" }}>
            Flight No.
          </p>
          <p style={{ margin: "5px 0", color: "#666" }}>{af.actual_flight_number}</p>

          <p style={{ margin: "0", fontWeight: "bold", fontSize: "13px", marginTop: "10px" }}>
            Airline
          </p>
          <p style={{ margin: "5px 0", color: "#666", fontSize: "12px" }}>
            {af.actual_airline} #
          </p>

          <p style={{ margin: "0", fontWeight: "bold", fontSize: "13px", marginTop: "10px" }}>
            Airport of origin
          </p>
          <p style={{ margin: "5px 0", color: "#666", fontSize: "12px" }}>
            {af.actual_airport_of_origin}
          </p>
        </div>

        <div style={{ flex: 2, padding: "0 15px", marginTop: "5px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
              gap: "15px",
            }}
          >
            <div style={{ width: "45%", wordWrap: "break-word" }}>
              <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>Departure</p>
              <p style={{ margin: "5px 0", color: "#666" }}>
                {af.actual_departure_time}
              </p>
            </div>
            <div style={{ width: "45%", wordWrap: "break-word" }}>
              <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>Travel Date</p>
              <p style={{ margin: "5px 0", color: "#666" }}> {af.actual_date_of_travel
                              ? moment.parseZone(af.actual_date_of_travel).format("YYYY-MM-DD")
                              : ""}</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
              gap: "15px",
            }}
          >
            <div style={{ width: "45%", wordWrap: "break-word", overflowWrap: "break-word" }}>
              <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
                Airport of destination
              </p>
              <p style={{ margin: "5px 0", color: "#666" }}>{af.actual_airport_of_destination}</p>
            </div>
            <div style={{ width: "45%", wordWrap: "break-word", overflowWrap: "break-word" }}>
              <p style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>Arrival Date</p>
              <p style={{ margin: "5px 0", color: "#666" }}>{af.actual_arrival_time}</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "15px",
            }}
          >
            <div style={{ width: "30%", wordWrap: "break-word", overflowWrap: "break-word" }}>
              <p style={{ margin: "0", fontWeight: "bold", fontSize: "13px" }}>
                Provided by airline
              </p>
              <p style={{ margin: "5px 0", color: "#666", fontSize: "12px" }}>
                {af.provided_by_airline ? "Yes" : "No"}
              </p>
            </div>

            <div style={{ width: "30%", wordWrap: "break-word", overflowWrap: "break-word" }}>
              <p style={{ margin: "0", fontWeight: "bold", fontSize: "13px" }}>Self booked</p>
              <p style={{ margin: "5px 0", color: "#666", fontSize: "12px" }}>
                {af.self_booked ? "Yes" : "No"}
              </p>
            </div>

            {/* <div style={{ width: "30%", wordWrap: "break-word", overflowWrap: "break-word" }}>
              <p style={{ margin: "0", fontWeight: "bold", fontSize: "13px" }}>Confirmation No.</p>
              <p style={{ margin: "5px 0", color: "#666", fontSize: "12px" }}>ZXRUAG11</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  ))}
</div>
)}

{familyMembers.length > 0 && (
  <div>
    <hr />
    <h1
      style={{
        marginBottom: "15px",
        fontSize: "17px",
        color: "#555",
        fontFamily: "Arial, sans-serif",
        marginTop: "20px",
      }}
    >
      Other Passenger Info
    </h1>

    <p style={{ margin: 0, fontWeight: "bold", fontSize: "20px" }}>PASSENGER</p>
    <br />

    {familyMembers.map((passenger) => (
      <div
        key={passenger.id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "700px",
          borderTop: "1.5px solid #999a99"
        }}
      >
        <div style={{ flex: 1, marginTop: "5px" }}>
          <div style={{ marginBottom: "25px" }}>
            <p style={{ margin: 0, fontWeight: "bold", fontSize: "15px" }}>
              First Name
            </p>
            <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
              {passenger.first_name}
            </p>
          </div>

          <div style={{ marginBottom: "25px" }}>
            <p style={{ margin: 0, fontWeight: "bold", fontSize: "15px" }}>
              Last Name
            </p>
            <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
              {passenger.last_name}
            </p>
          </div>

          <div style={{ marginBottom: "25px" }}>
            <p style={{ margin: 0, fontWeight: "bold", fontSize: "15px" }}>
              Date of Birth
            </p>
            <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
              {passenger.date_of_birth}
            </p>
          </div>
        </div>

        <div style={{ flex: 1, marginTop: "5px" }}>
          <div style={{ marginBottom: "25px" }}>
            <p style={{ margin: 0, fontWeight: "bold", fontSize: "15px" }}>
              Tickets No
            </p>
            <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
              {passenger.tickets}
            </p>
          </div>

          <div style={{ marginBottom: "25px" }}>
            <p style={{ margin: 0, fontWeight: "bold", fontSize: "15px" }}>
              Passport No
            </p>
            <p style={{ margin: "5px 0", color: "#666", fontSize: "13px" }}>
              {passenger.passport_number}
            </p>
          </div>
        </div>
      </div>
    ))}

    <br />
    <hr />
  </div>
)}
      <h1
        style={{
          marginBottom: "20px",
          fontSize: "17px",
          color: "#555",
          fontFamily: "Arial, sans-serif",
          marginTop: "20px",
        }}
      >
        Notes
      </h1>

      <p
        style={{
          color: "#888b8d",
          fontSize: "12px",
          fontWeight: "300",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      >
        {note.timestamp}
      </p>

      <p
        style={{
          color: "#292825",
          fontSize: "16px",
          fontWeight: "300",
          marginTop: "0px",
        }}
      >
        From {note.user}: {note.text}
      </p>

      <br />

      <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
        <span style={{ color: "red" }}>Claim ID:</span> {claimId}
      </p>
      <div>
        <h6 style={{ fontSize: "18px", marginBottom: "10px" }}>
          Conversation:
        </h6>
        {messages.length > 0 ? (
          <ListGroup style={{ marginBottom: "0" }}>
            {messages.map((message, index) => (
              <ListGroup.Item
                key={index}
                style={{
                  padding: "5px 10px",
                  textAlign: message.user === "customer" ? "left" : "right",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent:
                    message.user === "customer" ? "flex-start" : "flex-end",
                }}
                className={
                  message.user === "customer"
                    ? "customer-message"
                    : "lawyer-message"
                }
              >
                <div
                  style={{
                    padding: "8px",
                    backgroundColor:
                      message.user === "customer" ? "#d4e6f1" : "#e2f1d4",
                    borderRadius: "8px",
                    maxWidth: "80%",
                  }}
                  className="message-bubble"
                >
                  <span
                    style={{ fontSize: "12px", color: "#999" }}
                    className="message-time"
                  >
                    {message.timestamp}
                  </span>
                  <br />
                  <span
                    style={{ display: "block" }}
                    className="message-content"
                  >
                    From {message.user}:{message.note}
                  </span>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <p>No notes available</p>
        )}
      </div>
    </div>
    </>
  );
};

export default PrintNote;
